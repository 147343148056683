@import "../../styles/vars";

.ContCaret {
  position: relative;
  i::before {
    text-align: center;
    line-height: 1.5rem;
    position: absolute;
    background: transparent;
    font-size: 2.2rem;
    font-weight: bold;
    left: 0.2rem;
    top: 1rem;
    cursor: pointer;
    z-index: 1000;
  }
  input {
    padding-left: 2.5rem !important;
  }
  label {
    padding-left: 2.8rem !important;
  }
}

/***********************InputFile.js**********************/
