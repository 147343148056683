.accordion-button:not(.collapsed) {
  color: #000;
  background-color: transparent;
}
.accordion-button {
  color: $color;
}

[class^="bi-"]::before,
[class*=" bi-"]::before {
  line-height: 1.3;
}
