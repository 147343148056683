body {
  background-color: $bg;
  color: $color;
  font-family: "Noto Sans JP", sans-serif;
  font-size: 0.8rem;
  overflow: hidden;
}
.center-f {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center-abs {
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%) !important;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  width: 80%;
}

.m-auto {
  margin: auto;
}

.btnColor {
  color: #fff;
}

.iconLM {
  font-size: 16px;
  font-weight: bold;
}

.iconH {
  font-size: 25px;
  color: $colorIcon;
}

.textLM {
  font-size: 13px;
}

::-webkit-scrollbar {
  width: 1px;
  height: 1px;
  color: rgba(0, 0, 0, 0.5) !important;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.5) !important;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.5) !important;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5) !important;
  width: 5px;
}

:root {
  scrollbar-color: rgba(0, 0, 0, 0.2) rgba(0, 0, 0, 0.2) !important;
  scrollbar-width: thin;
}

.stikyNav {
  position: sticky;
  top: 68px;
  z-index: 900;
  //background-color: #fff;
}

.ck-editor__main {
  min-height: 200px;
  max-height: 500px;
  overflow-y: scroll;
  > div {
    min-height: 200px;
    max-height: 500px;
  }
}
