@import "../../styles/vars.scss";

.container {
  width: 100%;
}

.card {
  width: 520px;
  margin: auto;
  @media screen and (max-width: $screen-md) {
    width: 100%;
  }
}

.img {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 320px !important;
    height: auto;
    @media screen and (max-width: $screen-md) {
      width: 100% !important;
    }
  }
}
