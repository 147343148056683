/*
* Colores
*/
$bg: #ebeef4;
$color: #869ac0;
$bgBtn: #0050eb;
$colorBtn: #fff;
$colorIcon: #092d74;

/**
*Tamaños de pantallas
*/
$screen-sm: 544px;
$screen-md: 769px;
$screen-lg: 991px;
$screen-llg: 1200px;
