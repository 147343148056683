@import "../../styles/vars";
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/maps";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/animatewithsass/animate.scss";

.containerWait {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-color: $bg;
  z-index: 100;
  margin: auto;
  top: 0;
  left: 0;
  &_size {
    width: 4rem;
    height: 4rem;
  }
  &_exit {
    @include flipOutY();
  }
}
