@import "../../../styles/vars.scss";

.backgroundImg {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 220px;
}

.cardProfile {
  &_img {
    margin: auto;
    width: 100%;
    img {
      width: 220px;
      height: auto;
    }
  }
}
