@import "../styles/vars";
@import "../../node_modules/bootstrap/scss/functions";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/maps";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/root";
@import "../../node_modules/bootstrap/scss/containers";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/animatewithsass/animate.scss";

.container {
  margin-top: 68px;
}
.containerWait {
  position: absolute;
  height: 100vh;
  width: 100%;
  background-color: $bg;
  z-index: 100;
  margin: auto;
  top: 0;
  left: 0;
  &_size {
    width: 4rem;
    height: 4rem;
  }
  &_exit {
    @include flipOutY();
  }
}

.showLM {
  @extend .col-md-2;
  @include bounceInLeft();
  @media screen and (max-width: $screen-md) {
    position: absolute;
    z-index: 2;
    background-color: $bg;
  }
}

.hideLM {
  display: none;
  @include bounceOutLeft();
}

.main {
  @extend .col-md-10;
  @include fadeInLeft();
}

.mainAll {
  @extend .col-md-12;
  @include fadeInRight();
}
