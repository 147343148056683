.container {
  position: relative;
  height: 274px;
  border: dashed 2px #e2e2e2;
  background-color: #fcfcfc;
  width: 100%;
  padding: 2px;
  overflow: hidden;
}
.containerInput {
  position: absolute;
  left: 50%;
  top: 50%;
  -ms-transform: translate(-50%, -50%) !important;
  -webkit-transform: translate(-50%, -50%) !important;
  transform: translate(-50%, -50%) !important;
  width: 70%;
}

.containerInputFile {
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 90%;
    height: auto;
  }
}
.inputFile:hover {
  cursor: pointer;
}
.inputFile {
  opacity: 0;
  overflow: hidden;
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.inputFile + label {
  border: solid 2px #f2a000;
  background-color: #f2a000;
  color: #fff;
  font-size: 1.125rem;
  margin-top: 0.6875rem;
  z-index: -1;
}
