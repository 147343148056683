@import "../../styles/vars.scss";

.header {
  box-shadow: 0 6px 6px rgba(31, 107, 255, 0.1);
}

.menu {
  &_ico {
    cursor: pointer;
    font-size: 35px;
  }
}
